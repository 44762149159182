.logos-img {
    max-width: 200px; 
    height: auto;
}
.logout{
margin-left: 10px;
width: 60px;
height: 40px;
}
.bibi-box-arrow-right{
text-align: center;
}