.custom-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.titles tr {
  background-color: #0557aa;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.vetical-title {
  font-weight: bold;
}

.custom-table th,
.custom-table td {
  padding: 12px 10px;
}

.custom-table tr td {
  text-align: center;
}

.custom-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.custom-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.custom-table tbody tr:last-of-type {
  border-bottom: 2px solid #0557aa;
}

.custom-table tbody tr td span {
  font-weight: bold;
}

.td-nombre {
  text-align: left !important;
}

