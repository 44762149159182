.global-container{
	height:100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f1f2f3;
}

form{
	padding-top: 10px;
	font-size: 14px;
	margin-top: 30px;
}

.card-title{ font-weight:300; }

.btn{
	font-size: 14px;
	margin-top:20px;
}

.login-form{ 
	width:390px;
	margin:20px;
	border-bottom: solid 2px #0054a8;
}

.sign-up{
	text-align:center;
	padding:20px 0 0;
}

.login-logo {
    margin: 25px auto;
    width: 120px;
    height: auto;
}